// Variables
@import "variables";
@import "menu";

$red: #b31000;
$yellow: yellow;

// https://css-tricks.com/snippets/css/fluid-typography/
$min_width: 375px;
$max_width: 1500px;
$min_font: 28px;
$max_font: 32px;


@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

html {
	height: 100%;
}

body {
  height: 100%;
  font-size: 1.2em;
  /* text-align:center; */
  background-color: white;
  font-family: Trebuchet MS, Lucida Sans Unicode, Arial, sans-serif;
  margin: 0px;
  padding: 0px; }

a {
	color: $red;

	&:link, &:visited {
	  color: $red;
	  text-decoration: none; 
	}
	&:hover {
	  text-decoration: underline; 
	}
}

p {
	margin: 1em 0;
}

/* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.wrap-long {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.hover-image {
	position: relative;

	.hover-thumb {
		width: 50px;
	}

	.hover-full {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 200px;
	}
}

#app {
  min-height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 130px auto 40px;
  grid-template-areas: 
    "header"
    "middle"
    "footer";
}

#header-wrapper {
	grid-area: header;
	background-color: $yellow;
	padding: 0.5em;
	width: 100%;
	position: relative;
}

#header {
	
	display: flex;
	justify-content: center;

	.center-block {
		display: flex;
		justify-content: center;
	}
}

#menu-toggle {
	font-size: 2em;
	position: absolute;
	bottom: 8px;
	right: 8px;
	cursor: pointer;
}

#middle {
	grid-area: middle;
	min-height: 100%;
}

#we-deliver-block {
	width: 100%;
	color: #800;
	text-align: center;
	font-family: arial;
	padding: 0 0.5em;
	font-weight: bold;
	.we-deliver {
		font-size: 1em;
		
	}
	.food-types {
		display: none;
	}
	.phone {
		font-size: 1em;
	}
	.address {
		font-size: 0.8em;
	}
}

#navigation {
	list-style: none;
	padding-left: 0;
	padding-top: 0;
	li {
		border: medium #808080 solid;
		padding: 0;
		margin: 0;
		line-height: 1;
		font-size: 1.5em;
		font-weight: bold;
		a {
			display: block;

			&:hover {
				text-decoration: none;
				background-color: $yellow;
			}

			&.active {
				background-color: #555;
				color: white;
			}
		}
	}
}

#main {
	min-height: 100%;
	padding: 1em;
}

#footer {
	grid-area: footer;
	text-align: center;
	border-top: thin solid black;
	margin-top: 0.5em;
}


// Page styles
#home {
	text-align: center;
	img {
		max-width: 100%;
	}
}

#catering {
	.catering-section {
  		padding: 0.5em 1em; 
  	}

	.catering-label {
  		font-weight: bold; 
  	}
}

#contactus {
	// font-size: 2em;
	vertical-align: top;
	.label {
		font-weight: bold;
	}
	@include fluid-type($min_width, $max_width, $min_font, $max_font);
}

#delivery {
	font-size: 1.2em;
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	max-width: 500px;
	padding: 1em;
	img {
		max-width: 100%;
	}
 }

#directions {
	margin: 0 auto;
	max-width: 40em; 

	img {
		max-width: 100%;
	}
}

 #familystores {
	@include fluid-type($min_width, $max_width, $min_font, $max_font);
	vertical-align: top;
	.label {
		font-weight: bold;
	}
	.familystore {
		margin-bottom: 1em;
	}
}

#hours {
	// font-size: 1.5em;
	@include fluid-type($min_width, $max_width, $min_font, $max_font);
	vertical-align: top;
	.label {
		font-weight: bold;
	}
}

#pics {
	margin: 0 auto;
}

.pics-thumbs {
  padding: 1em;
  margin: 1em;
  width: 240px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  border: thin dashed black;
  /*height: 13em;*/
  min-height: 13em;
  text-align: center;
  vertical-align: top; 
  cursor: pointer;
}

.pics-thumbs .pics-comment {
  font-weight: bold; }


@media (min-width: 768px) {
	body {
		font-size: 1em;
	}
	#header {
		justify-content: space-between;
	}
	#we-deliver-block {
		letter-spacing: 0.3em;
		.we-deliver {
			font-size: 1.1em;
			
		}
		.food-types {
			display: block;
			font-size: 0.6em;
		}
		.phone {
			font-size: 1.1em;
		}
		.address {
			font-size: 0.6em;
		}		
	}
	#menu-toggle {
		display: none;
	}

	#middle {
		display: flex;
	}


	#left-sidebar {
		flex: 0 0 12em;
		display: block;	
	}

	#main {
		flex: 1;
	}

	// pages 
	#contactus {
		.label {
			text-align: right;
		}
	}

	#familystores {
		font-size: 2em;
		.label {
			text-align: right;	
		}
		
	}

	#hours {
		font-size: 2.5em;
		.label {
			text-align: right;
		}
	}	
  
}

