/****************************
 *********menu page***********
 *****************************/

.menu-print {
  display: flex;
  justify-content: flex-start;
}

.menu-screen {
  margin: 0 auto;   
}

.menu-container {
  max-width: 40em;

  h4 {
    font-size: 1.7em;    
  }

  p {
    margin: 0;
  }
  /* menu section */
  .menu-section {
    // max-width: 30em;
    // /* menu section title */
    .menu-section-title {
      font-weight: bold;
      font-size: 1.8em;
      text-align: center;
      margin-bottom: 1em; 
    }
    /* menu item */
    .menu-item {
      padding: 1em;
      .menu-item-name-price-row {
        display: flex;
        justify-content: space-between;
        vertical-align: bottom;
      }
    }
    .menu-table-wrapper {
      overflow-x: auto;
    }
    /* menu table */
    .menu-table {
      font-size: 0.7em;
      margin: 0;
      border-collapse: collapse;
      & td, & th {
        border-right: thin solid black;
        border-bottom: thin solid black;
        padding: 0.5em;
      }
      & th {
        border-width: medium;
      }               
    }   
    /* 3-col */
    .pk-menu-3-col {
      // clear: both;
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-flow: row wrap;
      .column {
        width: 200px;
        text-align: center;
        margin-bottom: 1em;
      }

      .title {
        font-weight: bold; 
      }     
    }   
    /* 2-col-center */
    div.pk-menu-2-col-center {
      width: 100%;
      padding-bottom: 2em; 
      .pk-menu-row {
        display: flex;
        justify-content: space-evenly;
        font-weight: bold;
      }

    }
    /* Dictionary */
    dl.pk-menu-dict {
      dt {
        font-weight: bold; 

          clear: left;
          float: left;
          width: 6em;       
      }
      dd {
          clear: right;
          margin-left: 6em;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    h4, .subheader {
      font-weight: bold;
      font-size: 1.7em;
      margin: 1em 0; 
    }
    /* feast */
    .feast-item {
      .feast-title {
        font-weight: bold;
        text-decoration: underline;
      }
      .feast-toppings {
        width: 30em;
        &::before {
          content: '- '
        }
      }
    }
  }
  .menu-subsection {
    margin-bottom: 1em;
    clear: both;
  }
}


#menu-download {
  text-align: right;
  font-size: 1.5em;
}



th {
  margin: 0;
}





#print-container * {
  font-size: 10pt; 
}

#print-container .menu-section {
  width: 99%;
}

#print-container .menu-row {
  clear: both;
/*  width: 100%; */
}

#print-container .menu-cell {
  width: 48%;
  margin: 0.5%;
  border: thin solid black;
  
}

#print-container .left {
  float: left;
}

#print-container .right {
  float: right;
}

input[type='text'], textarea {
  border: gray medium solid;
}

@media (min-width: 400px) {
  .menu-container {
    .menu-section {
      .menu-table {
        font-size: 1.2em;
      }
    }
  }
}
